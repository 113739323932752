import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [{
	path: '/',
	name: '首页',
	redirect: '/changdi'
}, {
	path: '/login',
	name: 'login',
	component: () => import('views/login/login.vue')
}, {
	path: '/changdi',
	name: '场地',
	component: () => import('views/changdi/changdi.vue')
}, {
	path: '/huodong',
	name: '活动',
	component: () => import('views/huodong/huodong.vue')
}, {
	path: '/huodongdingdan',
	name: '活动订单',
	component: () => import('views/huodongdingdan/huodongdingdan.vue')
}, {
	path: '/huiyuan',
	name: '会员',
	component: () => import('views/huiyuan/huiyuan.vue')
}, {
	path: '/chongzhijilu',
	name: '充值记录',
	component: () => import('views/chongzhijilu/chongzhijilu.vue')
}, {
	path: '/dingdan',
	name: '订单',
	component: () => import('views/dingdan/dingdan.vue')
}, {
	path: '/azdingdan',
	name: '安装订单',
	component: () => import('views/azdingdan/azdingdan.vue')
}]

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})