import Vue from 'vue'
import App from './views/App.vue'
 
import ElementUI from 'element-ui'
// import './plugins/element.js'
import router from 'tools/router'
import './assets/css/base.css' 
import store from 'tools/store'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element_change.css'
import './assets/css/element_change2.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
	store
}).$mount('#app')
