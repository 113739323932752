import vue from 'vue'
import vuex from 'vuex'

vue.use(vuex)

export default new vuex.Store({
	state: {
		user_info: {
			quanxian: [],
			user_headimg: "123",
			user_id: 0,
			user_nickname: "",
			user_state: 0
		}
	},
	mutations: {
		//this.parent.$store.user_info.quanxian = res.data.quanxian;
	},
	actions: {

	}
})
